
.MainBox {
    background: #fcfcfc;
    max-width: 800px!important;
    padding: 1.40em 15px;
}

.cont {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0.5em;
}